<template>
  <form @submit.prevent="saveInformation">
    <div class="card card-outline">
      <div class="card-header mt-2">
        <i class="material-icons-outlined icon-wrap icon-wrap-primary mb-3">&#xe873;</i>
        <h2 class="typography-headline-md fw-500">Restaurant General Information</h2>
      </div>
      <div class="card-body">
        <div v-if="errorMessage" class="row">
          <div class="col-md-12">
            <div class="alert alert-danger">
              {{ errorMessage }}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <p class="paragraph">General</p>
          </div>
          <div class="col-md-6 col-xl-12 mb-3">
            <label for="restaurant-name" class="mb-2">Restaurant name</label>
            <input v-model="restaurantName"
                   :disabled="isLoading"
                   placeholder="For example: Coffee Tech"
                   id="restaurant-name"
                   type="text"
                   class="form-control">
          </div>
          <div class="col-md-6 col-xl-4 mb-3">
            <label for="restaurant-subdomain" class="mb-2">
              Subdomain
            </label>
            <div class="input-group">
              <input v-model="restaurantSubdomain"
                     :disabled="isLoading"
                     type="text" class="form-control"
                     placeholder="For example: coffee-tech"
                     aria-label="For example: coffee-tech"
                     aria-describedby="restaurant-subdomain">
              <span class="input-group-text" id="restaurant-subdomain">.menuffy.com</span>
            </div>
          </div>
          <div class="col-md-6 col-xl-4 mb-3">
            <label class="mb-2">Category</label>
            <select v-model="category" id="input-category" class="form-select form-control">
              <option value="Pizza">Pizza</option>
              <option value="Sushi">Sushi</option>
              <option value="Desserts">Desserts</option>
              <option value="Burgers">Burgers</option>
              <option value="Chicken">Chicken</option>
              <option value="Mexican">Mexican</option>
              <option value="Coffee and Tea">Coffee and Tea</option>
              <option value="American">American</option>
              <option value="Seafood">Seafood</option>
              <option value="Asian">Asian</option>
              <option value="BBQ">BBQ</option>
              <option value="Vegan">Vegan</option>
              <option value="European">European</option>
              <option value="Alcohol">Alcohol</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="col-md-6 col-xl-4 mb-3">
            <label for="restaurant-name" class="mb-2">Currency</label>
            <input v-model="restaurantCurrency"
                   :disabled="isLoading"
                   placeholder="For example: MXN, USD, EUR, CAD, JPY"
                   id="restaurant-currency"
                   type="text"
                   class="form-control">
          </div>

          <div class="col-md-12">
            <hr>
            <p class="paragraph">Branding</p>
            <div class="alert alert-sm alert-info">
              <i class="material-icons-outlined icon-wrap me-1">&#xe8fd;</i>
              <span class="fw-bold">TIP:</span> Use a strong color for primary and soft color for the background
            </div>
          </div>
          <div class="col-md-6 col-xl-4 mb-3">
            <label for="primary-color" class="mb-2">Primary color</label>
            <div class='d-flex align-items-center flex-nowrap'>
              <input v-model="primaryColor" maxlength="7" id="primary-color" class="form-control me-2 mb-2 mb-lg-0"
                     type="text">
              <input v-model="primaryColor"
                     type="color" class="form-control form-control-color mb-2 mb-lg-0">
            </div>
          </div>
          <div class="col-md-6 col-xl-4 mb-3">
            <label for="background-color" class="mb-2">Background color</label>
            <div class='d-flex align-items-center flex-nowrap'>
              <input id="background-color" maxlength="7" class="form-control me-2 mb-2 mb-lg-0"
                     type="text"
                     v-model="backgroundColor">
              <input type="color" class="form-control form-control-color mb-2 mb-lg-0" v-model="backgroundColor">
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <button :disabled="isLoading" type="submit"
                class="btn btn-primary ripple px-5 py-3">
          {{ isLoading ? 'Saving...' : 'Save information' }}
        </button>
        <div>
          <button @click="logout" class="btn btn-link">
            Sign out and go back
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {functions, db, firebaseAuth} from '@/firebase'
import {httpsCallable} from 'firebase/functions'
import {getDoc, doc} from 'firebase/firestore'
import {Toast} from "@/utils";
import {signOut} from "firebase/auth";

export default {
  props: ['firstTime'],
  name: 'RestaurantInformationCard',
  data() {
    return {
      primaryColor: '#ff6160',
      backgroundColor: '#ffecec',
      restaurantName: '',
      restaurantSubdomain: '',
      restaurantCurrency: '',
      category: 'Pizza',
      errorMessage: null,
      isLoading: false,
    }
  },
  mounted() {
    this.fetchInformation()
  },
  methods: {
    async fetchInformation() {
      if (this.firstTime) {
        return
      }

      const restaurantRef = doc(db, 'restaurants', firebaseAuth.currentUser.uid)
      const restaurantSnap = await getDoc(restaurantRef)
      const restaurantData = restaurantSnap.data()

      if (!restaurantData) {
        return
      }

      const {name, backgroundColor, currency, primaryColor, category, subdomain} = restaurantData

      this.restaurantName = name
      this.restaurantSubdomain = subdomain
      this.backgroundColor = backgroundColor
      this.primaryColor = primaryColor
      this.category = category
      this.restaurantCurrency = currency
    },
    async saveInformation() {
      this.isLoading = true
      this.errorMessage = null
      const restaurantInfo = httpsCallable(functions, 'saveRestaurantInfo')

      restaurantInfo({
        primaryColor: this.primaryColor,
        backgroundColor: this.backgroundColor,
        name: this.restaurantName,
        subdomain: this.restaurantSubdomain,
        currency: this.restaurantCurrency,
        category: this.category,
      })
          .then(() => {
            if (this.firstTime) {
              return this.$router.replace({name: 'CreateLocation'})
            }

            Toast.fire({
              icon: 'success',
              title: 'Restaurant settings were saved successfully.'
            })
          })
          .catch(err => {
            this.errorMessage = err.message
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    logout() {
      signOut(firebaseAuth)
          .then(() => this.$router.replace({ name: 'SignIn' }))
    }
  }
}
</script>
