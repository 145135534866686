<template>
  <div class="card card-outline">
    <div class="card-header mt-2">
      <i class="material-icons-outlined icon-wrap icon-wrap-primary mb-3">&#xe3b6;</i>
      <h2 class="typography-headline-md fw-500">Restaurant Images</h2>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-12">
          <p class="paragraph mb-1">Images</p>
          <p class="subtitle-1 text-muted">Types: PNG, JPG, JPEG. Up to 5 MB size</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <img v-if="restaurantLogoUrl"
               :src="restaurantLogoUrl" alt="Restaurant logo" class="img-fluid img-input-file mb-3">

          <InputFile label="Restaurant logo" id="restaurant-logo" v-model="restaurantLogo"/>
        </div>
        <div class="col-md-6 mb-3">
          <img v-if="restaurantBannerUrl"
               :src="restaurantBannerUrl" alt="Restaurant banner" class="img-fluid img-input-file mb-3">
          <InputFile label="Restaurant banner" id="restaurant-logo" v-model="restaurantBanner"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InputFile from '@/components/InputFile'
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import {db, firebaseAuth} from '@/firebase'
import {doc, setDoc, getDoc} from "firebase/firestore";


export default {
  components: {InputFile},
  data() {
    return {
      restaurantLogo: null,
      restaurantBanner: null,

      restaurantLogoUrl: null,
      restaurantBannerUrl: null,
    }
  },
  watch: {
    restaurantLogo: async function (value) {
      await this.uploadImage(value, 'logo')
    },
    restaurantBanner: async function (value) {
      await this.uploadImage(value, 'banner')
    }
  },
  mounted() {
    this.fetchImages()
  },
  methods: {
    async fetchImages() {
      const logoImageDocRef = doc(db, 'restaurants', firebaseAuth.currentUser.uid, 'images', 'logo')
      const logoImageDocSnap = await getDoc(logoImageDocRef)
      const logoImageDocData = logoImageDocSnap.data()

      const bannerImageDocRef = doc(db, 'restaurants', firebaseAuth.currentUser.uid, 'images', 'banner')
      const bannerImageDocSnap = await getDoc(bannerImageDocRef)
      const bannerImageDocData = bannerImageDocSnap.data()

      this.restaurantLogoUrl = logoImageDocData?.imageURL
      this.restaurantBannerUrl = bannerImageDocData?.imageURL
    },
    async uploadImage(image, type) {
      const storage = getStorage()
      const mimeType = image.name.split(".")[1]

      const imagePath = `restaurants/${firebaseAuth.currentUser.uid}/${type}.${mimeType}`
      const imageRef = ref(storage, imagePath);
      const imageURL = await uploadBytes(imageRef, image).then(snapshot => getDownloadURL(snapshot.ref))

      const imageDocRef = doc(db, `restaurants/${firebaseAuth.currentUser.uid}/images/${type}`)

      await setDoc(imageDocRef, {imageURL, imagePath})

      if (type === 'logo') {
        this.restaurantLogoUrl = imageURL
        this.restaurantLogo = null
      } else if (type === 'banner') {
        this.restaurantBannerUrl = imageURL
        this.restaurantBanner = null
      }
    }
  }
}

</script>
