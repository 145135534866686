<template>
  <div>
    <TheNavbar/>
    <div class="container mt-4">

      <!-- SETTINGS TITLE -->
      <div class="row mb-3">
        <div class="col-md-12 animate fadeIn">
          <h1 class="fw-500 typography-headline-lg">Settings</h1>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12 mb-3">
          <RestaurantInformationCard/>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-12 mb-3">
          <RestaurantImagesCard/>
        </div>
      </div>

      <!--      &lt;!&ndash; PAYMENT OPTIONS &ndash;&gt;-->
      <!--      <div class="row mb-3">-->
      <!--        <div class="col-md-12 mb-3">-->
      <!--          <div class="card card-outline">-->
      <!--            <div class="card-header mt-2">-->
      <!--              <p class="overline text-muted">Payments Options</p>-->
      <!--            </div>-->
      <!--            <div class="card-body">-->
      <!--              <div class="row">-->
      <!--                <div class="col-md-12">-->
      <!--                  <div class="card card-outline p-3">-->
      <!--                    <div class="card-body d-flex justify-content-between pb-0">-->
      <!--                      <div>-->
      <!--                        <p class="subtitle-1 fw-500 mb-1">Enable Stripe payments</p>-->
      <!--                        <p class="subtitle-2 text-muted">-->
      <!--                          Accept digital payments with a Stripe Checkout account-->
      <!--                        </p>-->
      <!--                      </div>-->
      <!--                      <div class="align-self-center">-->
      <!--                        <div class="theme-switch">-->
      <!--                          <label class="switch">-->
      <!--                            <input type="checkbox" id="checkbox" name="checkbox">-->
      <!--                            <span class="slider round"></span>-->
      <!--                          </label>-->
      <!--                        </div>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

    </div>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar"
import RestaurantInformationCard from '@/components/RestaurantInformationCard'
import RestaurantImagesCard from '@/components/RestaurantImagesCard'

export default {
  components: {TheNavbar, RestaurantInformationCard, RestaurantImagesCard}
}
</script>
